<template>
  <button
    ref="reference"
    type="button"
    :disabled="disabled"
    :class="$style.buttonComponent"
    class="group/button"
    data-hide-from-share
  >
    <slot />
    <slot name="title">
      <div
        ref="floating"
        v-if="title && !navigator.userAgent.includes('Mobile')"
        :style="floatingStyles"
        class="pointer-events-none z-10 mx-auto flex w-max translate-y-full items-center gap-2 text-ellipsis whitespace-nowrap rounded-md bg-white px-2 py-1 text-left text-xs text-neutral-700 opacity-0 shadow-md group-hover/button:opacity-100 group-hover/button:transition-opacity group-hover/button:delay-300 dark:bg-black dark:text-neutral-300 dark:ring-1 dark:ring-neutral-700 dark:drop-shadow-none"
      >
        <span v-html="title" />
        <kbd
          v-if="shortcode"
          class="inline-flex h-5 min-w-5 items-center justify-center gap-x-0.5 rounded border border-neutral-400 bg-white px-0.5 font-semibold text-neutral-900 dark:border-neutral-600 dark:bg-black dark:text-neutral-200"
        >
          <span v-if="shortcodeMeta">{{ $metaKey }}</span>
          <span>{{ shortcode.toUpperCase() }}</span>
        </kbd>
      </div>
    </slot>

    <!-- <OverlayComponent v-if="help" @click.stop="help = false" />
    <div
      v-if="help"
      @click.stop
      class="absolute bottom-0 left-0 z-40 mx-auto w-max min-w-full translate-y-full cursor-default overflow-visible rounded-md bg-white px-2 py-1 text-left text-sm text-neutral-700 drop-shadow-md"
    >
      <slot name="help" />

      <div class="absolute right-0 top-0 -translate-y-1/3 translate-x-1/3">
        <ButtonComponent @click.stop="help = false" variant="round">
          <XMarkIcon class="w-5" />
        </ButtonComponent>
      </div>
    </div> -->
  </button>
</template>

<script setup>
import { ref } from "vue";
import { useFloating, shift, flip, autoUpdate, offset } from "@floating-ui/vue";

const reference = ref(null);
const floating = ref(null);

const { floatingStyles } = useFloating(reference, floating, {
  placement: "bottom-center",
  whileElementsMounted: autoUpdate,
  middleware: [
    offset(2),
    flip(),
    shift({
      crossAxis: true,
    }),
  ],
});
</script>

<script>
// import { CircleHelp } from "lucide-vue-next";
export default {
  components: {
    // CircleHelp,
  },
  props: {
    disabled: Boolean,
    title: String,
    shortcode: String,
    shortcodeMeta: Boolean,
  },
  data() {
    return {
      navigator: navigator,
      help: false,
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("keydown", this.onShortcode);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.onShortcode);
  },
  methods: {
    onShortcode(event) {
      if (!this.shortcode) return;

      const element = this.$el;
      const isVisible = !!(
        element.offsetWidth ||
        element.offsetHeight ||
        element.getClientRects().length
      );
      const isNotObscured = !this.isElementObscured(element);

      if (
        (this.$Cypress ||
          ((isVisible || event.key == "Enter") &&
            (isNotObscured || event.metaKey))) &&
        ((this.shortcode == "Enter" && event.key == "Enter") ||
          (this.shortcode == "Escape" && event.key == "Escape") ||
          (((this.shortcodeMeta && event.metaKey) ||
            (event.target.tagName !== "INPUT" &&
              event.target.isContentEditable !== true)) &&
            !this.disabled &&
            !event.repeat))
      ) {
        if (event.key === this.shortcode) {
          event.preventDefault();
          event.stopPropagation();
          this.$el.click();
        }
      }
    },

    isElementObscured(element) {
      const rect = element.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const topElement = document.elementFromPoint(centerX, centerY);
      return topElement !== element && !element.contains(topElement);
    },
  },
};
</script>

<style module lang="scss">
.buttonComponent {
  composes: button from "@/styles/buttons.module.scss";
}
</style>
