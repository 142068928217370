<template>
  <div
    class="mx-auto w-full sm:max-w-xl md:max-w-3xl md:px-8 lg:max-w-4xl xl:max-w-6xl"
  >
    <div
      :class="$style.entrySettingsInner"
      data-tour="main_entry_settings"
      ref="outer_tinted_wrapper"
      style="
        transition: height 0.8s cubic-bezier(0.16, 1, 0.3, 1);
        /* transition-delay: 0.3s; */
      "
    >
      <div ref="inner_tinted_wrapper">
        <TourHelper
          hook="entry_settings"
          class="absolute right-0 top-0 mr-2 mt-2"
          auto-start
          :showSettings="showSettings"
          @showSettings="showSettings = $event"
        />
        <TransitionGroupHelper
          :enter="{
            opacity: [0, 1],
            translateY: [50, 0],
          }"
          :leave="{
            opacity: [1, -0.2],
            translateY: [0, 50],
          }"
        >
          <div key="header" :class="$style.entryDisplay_wrapper">
            <CoverDisplay
              v-if="entry.cover"
              v-model="entry.cover"
              :entry="entry"
              class="group/cover mx-5 mb-4 h-48 overflow-hidden rounded-2xl shadow-lg md:h-[30vh]"
            >
              <CoverSettings
                v-model="entry.cover"
                key="cover"
                color="secondary"
                :entry="entry"
                class="absolute right-2 top-2 opacity-0 transition-opacity duration-200 ease-in-out group-hover/cover:opacity-100"
              />
            </CoverDisplay>
            <!-- {{ entry }} -->
            <EntryDisplay
              id="header"
              :modelValue="entry"
              :position="position"
              :display="{
                links: true,
                output: false,
              }"
              size="4xl"
              :class="{
                [`${$style.entryDisplay}`]: true,
                [`${$style.entryDisplay_smaller}`]: entry.name.length > 30,
              }"
              :data-test="$slugify(entry.name)"
              :disableViewportChecking="true"
            >
              <template #settings>
                <ButtonComponentNew
                  @click="showSettings = !showSettings"
                  title="Settings"
                  shortcode="e"
                  :class="$style.buttonSettings"
                  class="duration-500 lg:mt-1"
                  :style="{
                    '--ps-button-bg-color': showSettings
                      ? 'var(--ps-base-background-color)'
                      : 'var(--ps-base-primary-color)',
                    '--ps-button-bg-color-hover': showSettings
                      ? 'var(--ps-base-secondary-color)'
                      : 'var(--ps-base-secondary-color)',
                    '--ps-button-color': showSettings
                      ? 'var(--ps-base-primary-color)'
                      : 'var(--ps-base-background-color)',
                    '--ps-button-color-hover': showSettings
                      ? 'var(--ps-base-background-color)'
                      : 'var(--ps-base-background-color)',
                  }"
                >
                  <IconHelper
                    name="chevron-down"
                    size="20"
                    :class="{
                      'rotate-180': showSettings,
                    }"
                    class="transition-transform"
                  />
                </ButtonComponentNew>
              </template>
              <template v-if="entry.description" #description>
                <div
                  class="-m-2 box-content w-full rounded-md p-2 ring-neutral-100 hover:ring-1 dark:ring-neutral-700"
                >
                  <DescriptionDisplay
                    v-model="entry.description"
                    :entry="entry"
                    :position="position"
                    class="w-full"
                  />
                </div>
              </template>
            </EntryDisplay>
          </div>
          <div
            v-if="showSettings || $Cypress"
            key="settings"
            class="mb-2 w-full px-5"
            data-tour="main_entry_toolbar"
          >
            <TransitionGroupHelper
              :enter="{
                opacity: [0, 1],
                translateY: [50, 0],
              }"
              :leave="{
                opacity: 0,
                translateY: 50,
              }"
            >
              <DescriptionSettings
                v-if="!entry.description"
                key="description"
                v-model="entry"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />
              <StatusSettings
                v-if="!entry.status_id"
                key="status"
                v-model="status"
                :entry="entry"
                :full="true"
                :statuses="statuses"
                variant="round"
                color="white"
                data-test="entry_status_no-status"
                class="mb-2 mr-2 inline-block"
              />
              <ScheduleSettings
                v-if="!entry.schedule"
                key="schedule"
                v-model="entry.schedule"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />
              <PrioritySettings
                v-if="!entry.priority"
                key="priority"
                v-model="entry.priority"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />
              <TimeTrackingsSettings
                key="time_trackings"
                v-model="entry.time_trackings"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />
              <!-- v-if="!entry.time_trackings.length" -->

              <OutputSettings
                key="output"
                v-if="!entry.output"
                v-model="entry.output"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />
              <InputSettings
                key="input"
                v-if="!entry.input"
                v-model="entry.input"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />
              <CoverSettings
                v-if="!entry.cover"
                v-model="entry.cover"
                key="cover"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              />

              <RoutineSettings
                v-model="entry.routine"
                key="routine"
                :entry="entry"
                variant="round"
                color="white"
                class="mb-2 mr-2 inline-block"
              >
                <template v-slot:button_routine_configure="{ routine }">
                  <ButtonComponent color="neutral" colorWeight="900">
                    <IconHelper name="repeat" size="20" />
                    {{ routine.target }}x
                    <span class="capitalize"> {{ routine.frequency }} </span>
                  </ButtonComponent>
                </template>
              </RoutineSettings>

              <div
                key="senses"
                v-if="position != 'modal'"
                class="mb-2 mr-2 inline-block"
              >
                <ButtonComponent
                  @click="showSenseModal = true"
                  variant="round"
                  color="white"
                  title="Senses"
                  data-test="entry_settings_action_senses"
                >
                  <IconHelper name="radio" size="20" />
                </ButtonComponent>
              </div>

              <div key="templates" class="mb-2 mr-2 inline-block">
                <ButtonComponent
                  @click="showTemplates = !showTemplates"
                  variant="round"
                  color="white"
                  title="Show Templates"
                  data-test="entry_page_templates_button"
                >
                  <IconHelper
                    name="puzzle"
                    size="20"
                    class="transition-transform"
                  />
                </ButtonComponent>
              </div>
              <!--
                <div
                key="focus"
                v-if="position != 'modal'"
                class="mb-2 mr-2 inline-block"
              >
                <ButtonComponent
                  @click="$store.dispatch('focus', entry)"
                  variant="round"
                  color="white"
                  title="Enter Focus Mode"
                  shortcode="F"
                  data-test="entry_settings_action_focus"
                >
                  <IconHelper name="maximize-2" size="20" />
                </ButtonComponent>
              </div> -->
              <div
                v-if="position != 'modal'"
                key="settings"
                class="mb-2 inline-block"
              >
                <SettingsDisplay
                  v-model="entry"
                  @click="$emit('showSettings')"
                  color="primary"
                />
              </div>
            </TransitionGroupHelper>
          </div>

          <div
            key="panel"
            class="flex"
            :class="{
              'h-9': !hasPanelContent,
              'h-full': hasPanelContent,
            }"
          >
            <PanelHelper
              :class="[
                'mx-auto',
                $style.entrySettingsPanel,
                !hasPanelContent
                  ? 'w-12 !rounded-full'
                  : 'w-full overflow-hidden',
              ]"
              ref="outer"
            >
              <div ref="inner">
                <Transition
                  enter-active-class="transition-all duration-500 delay-300"
                  enter-from-class="opacity-0 translate-y-5"
                  enter-to-class="opacity-100 translate-y-0"
                  _leave-active-class="transition-opacity duration-200 h-0"
                  _leave-to-class="opacity-0"
                  _leave-from-class="opacity-100"
                >
                  <div
                    key="content"
                    v-if="hasPanelContent"
                    class="flex w-full flex-col gap-y-4 divide-y p-5 dark:divide-neutral-700"
                  >
                    <TransitionGroupHelper>
                      <OutputDisplay
                        id="output"
                        :key="entry.output.id"
                        v-if="entry.output && performanceRenderIndex >= 0"
                        v-model="entry.output"
                        :entry="entry"
                        :display="{
                          links: entry.output.type != 'gallery',
                          output: entry.output.type != 'gallery',
                        }"
                        :filter="(e) => e.id != entry.id"
                        :inputs="linkedEntries"
                        :sorting="entry.output.sorting"
                        :editable="true"
                        :position="position"
                        data-tour="main_entry_output"
                      />

                      <InputDisplay
                        id="input"
                        key="input"
                        v-if="!entry.output && entry.input"
                        v-model="entry.input"
                        :entry="entry"
                        :schema="entry.input.schema"
                        :color="entry.color"
                      />
                      <div
                        v-for="(linkedEntry, index) in linkedEntries.slice(
                          0,
                          performanceRenderIndex,
                        )"
                        :key="entry.id + '_' + linkedEntry.id"
                        :class="{
                          '!pt-0': !entry.output && !entry.input && index == 0,
                        }"
                        class="flex flex-col gap-4 pt-4 lg:grid lg:grid-cols-4 lg:divide-x dark:divide-neutral-700"
                        data-test="entry_linked"
                      >
                        <div
                          class="flex flex-row-reverse justify-between gap-2 lg:flex-col lg:gap-8"
                        >
                          <div :class="$style.linkedEntryWrapper">
                            <EntryDisplay
                              :modelValue="linkedEntries[index]"
                              :permissions="{
                                name: false,
                                description: false,
                                status: false,
                                priority: false,
                                time_trackings: false,
                              }"
                              :display="{
                                links: false,
                                output: false,
                                routine: false,
                                custom_fields: false,
                              }"
                              :class="$style.linkedEntryDisplay"
                            >
                              <template #contextmenu>
                                <MenuItem v-if="index > 0" v-slot="{ active }">
                                  <ButtonComponent
                                    @click.stop="
                                      () => {
                                        const i = entry.links.indexOf(
                                          linkedEntry.id,
                                        );
                                        if (i > 0) {
                                          const item = entry.links.splice(
                                            i,
                                            1,
                                          )[0];
                                          entry.links.splice(i - 1, 0, item);
                                        }
                                        $store.dispatch('push', {
                                          event: 'entry_update',
                                          params: { entry: entry },
                                          entry: entry,
                                        });
                                      }
                                    "
                                    variant="menu"
                                    color="white"
                                    size="sm"
                                    :active="active"
                                    class="w-full rounded-md"
                                    title="Move this entry up"
                                    data-test="entry_linked_action_move_up"
                                  >
                                    <IconHelper
                                      name="ArrowUp"
                                      size="16"
                                      :class="{ 'text-neutral-500': active }"
                                    />
                                    <div
                                      class="flex flex-1 items-center justify-between"
                                    >
                                      <span> Move up </span>
                                    </div>
                                  </ButtonComponent>
                                </MenuItem>

                                <MenuItem v-slot="{ active }">
                                  <ButtonComponent
                                    @click.stop="
                                      () => {
                                        const i = entry.links.indexOf(
                                          linkedEntry.id,
                                        );
                                        if (i > -1) {
                                          entry.links.splice(i, 1);
                                        }
                                        $store.dispatch('push', {
                                          event: 'entry_update',
                                          params: { entry: entry },
                                          entry: entry,
                                        });
                                      }
                                    "
                                    variant="menu"
                                    color="white"
                                    size="sm"
                                    :active="active"
                                    class="w-full rounded-md"
                                    title="Unlink"
                                    data-test="entry_linked_action_unlink"
                                  >
                                    <IconHelper
                                      name="Link2OffIcon"
                                      size="16"
                                      :class="{ 'text-neutral-500': active }"
                                    />
                                    <div
                                      class="flex flex-1 items-center justify-between"
                                    >
                                      <span> Unlink </span>
                                    </div>
                                  </ButtonComponent>
                                </MenuItem>

                                <MenuItem
                                  v-if="index < entry.links.length - 1"
                                  v-slot="{ active }"
                                >
                                  <ButtonComponent
                                    @click.stop="
                                      () => {
                                        const i = entry.links.indexOf(
                                          linkedEntry.id,
                                        );
                                        if (i < entry.links.length - 1) {
                                          const item = entry.links.splice(
                                            i,
                                            1,
                                          )[0];
                                          entry.links.splice(i + 1, 0, item);
                                        }
                                        $store.dispatch('push', {
                                          event: 'entry_update',
                                          params: { entry: entry },
                                          entry: entry,
                                        });
                                      }
                                    "
                                    variant="menu"
                                    color="white"
                                    size="sm"
                                    :active="active"
                                    class="w-full rounded-md"
                                    title="Move this entry down"
                                    data-test="entry_linked_action_move_down"
                                  >
                                    <IconHelper
                                      name="ArrowDown"
                                      size="16"
                                      :class="{ 'text-neutral-500': active }"
                                    />
                                    <div
                                      class="flex flex-1 items-center justify-between"
                                    >
                                      <span> Move down </span>
                                    </div>
                                  </ButtonComponent>
                                </MenuItem>
                                <hr />
                              </template>
                            </EntryDisplay>
                          </div>
                          <div
                            class="flex flex-wrap gap-4 lg:w-full lg:justify-between"
                          >
                            <div>
                              <div
                                v-if="linkedEntries.length"
                                class="mr-2 mt-2 hidden gap-x-0.5 lg:mt-1.5 lg:flex"
                              >
                                <!-- <ButtonComponent
                                  v-if="index > 0"
                                  @click.stop="
                                    () => {
                                      const i = entry.links.indexOf(
                                        linkedEntry.id,
                                      );
                                      if (i > 0) {
                                        const item = entry.links.splice(
                                          i,
                                          1,
                                        )[0];
                                        entry.links.splice(i - 1, 0, item);
                                      }
                                      $store.dispatch('push', {
                                        event: 'entry_update',
                                        params: { entry: entry },
                                        entry: entry,
                                      });
                                    }
                                  "
                                  variant="minimal"
                                  title="Move up"
                                  data-test="entry_linked_action_move_up"
                                >
                                  <ArrowUpIcon class="h-4 w-4" />
                                </ButtonComponent>

                                <ButtonComponent
                                  @click.stop="
                                    () => {
                                      const i = entry.links.indexOf(
                                        linkedEntry.id,
                                      );
                                      if (i > -1) {
                                        entry.links.splice(i, 1);
                                      }
                                      $store.dispatch('push', {
                                        event: 'entry_update',
                                        params: { entry: entry },
                                        entry: entry,
                                      });
                                    }
                                  "
                                  variant="minimal"
                                  title="Unlink"
                                  data-test="entry_linked_action_unlink"
                                >
                                  <Link2OffIcon size="16" />
                                </ButtonComponent>

                                <ButtonComponent
                                  v-if="index < entry.links.length - 1"
                                  @click.stop="
                                    () => {
                                      const i = entry.links.indexOf(
                                        linkedEntry.id,
                                      );
                                      if (i < entry.links.length - 1) {
                                        const item = entry.links.splice(
                                          i,
                                          1,
                                        )[0];
                                        entry.links.splice(i + 1, 0, item);
                                      }
                                      $store.dispatch('push', {
                                        event: 'entry_update',
                                        params: { entry: entry },
                                        entry: entry,
                                      });
                                    }
                                  "
                                  variant="minimal"
                                  title="Move down"
                                  data-test="entry_linked_action_move_down"
                                >
                                  <ArrowDownIcon class="h-4 w-4" />
                                </ButtonComponent> -->
                              </div>
                            </div>
                            <OutputSettings
                              v-model="linkedEntry.output"
                              :entry="linkedEntry"
                              size="xs"
                              variant="minimal"
                              class="ml-auto hidden items-end gap-x-4 self-end lg:flex"
                              :showDelete="false"
                            />
                          </div>
                        </div>
                        <div class="lg:col-span-3 lg:pl-4">
                          <OutputDisplay
                            v-if="linkedEntry.output"
                            v-model="linkedEntry.output"
                            :display="{
                              links: linkedEntry.output.type != 'gallery',
                              output: linkedEntry.output.type != 'gallery',
                            }"
                            :entry="linkedEntry"
                            :filter="(e) => e.id != entry.id"
                            :inputs="linkedEntries"
                            :editable="true"
                            :showToolbar="false"
                            :sorting="linkedEntry.output.sorting"
                            :position="position"
                            :dateSelected="dateCursor"
                            @update:dateSelected="dateCursor = $event"
                            :class="$style.linkedOutputDisplay"
                          />
                          <InputDisplay
                            v-if="!linkedEntry.output && linkedEntry.input"
                            v-model="linkedEntry.input"
                            :entry="linkedEntry"
                            :schema="linkedEntry.input.schema"
                            :position="position"
                          />
                        </div>
                      </div>
                    </TransitionGroupHelper>
                  </div>
                  <div
                    key="add_input_output"
                    v-else
                    class="mx-auto flex h-12 w-12"
                  >
                    <ButtonComponentNew
                      @click="
                        output = addonBlueprint('output', entry, this);
                        input = addonBlueprint('input', entry, this);
                      "
                      title="Add Input & Output"
                      shortcode="+"
                      :class="$style.addInputOutputButton"
                    >
                      <PlusIcon size="28" />
                    </ButtonComponentNew>
                  </div>
                </Transition>
                <!-- <LinksSettings
                  key="links"
                  v-model="entry.links"
                  :entry="entry"
                  variant="round"
                  color="neutral"
                  colorWeight="900"
                  class="mx-5 pb-5"
                  data-tour="main_entry_links"
                /> -->
              </div>
            </PanelHelper>
          </div>
        </TransitionGroupHelper>
      </div>
    </div>

    <div class="mt-5 flex justify-center">
      <LinksPopover v-model="links" data-tour="main_entry_links">
        <template #button>
          <ButtonComponentNew
            title="Add Links"
            shortcode="b"
            :class="$style.addLinksButton"
            data-test="links_builder_add"
          >
            <Link2Icon size="20" />
          </ButtonComponentNew>
        </template>
      </LinksPopover>
    </div>

    <Transition
      enter-active-class="transition-all duration-[800ms] delay-[400ms]"
      enter-from-class="opacity-0 translate-y-5"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition-all duration-[800ms]"
      leave-to-class="opacity-0 translate-y-5"
      leave-from-class="opacity-100 translate-y-0"
    >
      <RestDisplay v-model="entry" class="mx-5 py-5" :position="position" />
    </Transition>

    <TemplateWrapper
      v-if="showTemplates"
      v-model="entry"
      @close="showTemplates = false"
    />

    <ModalHelper :show="showSenseModal" @close="showSenseModal = false">
      <TitleHelper> Configure Senses for {{ entry.name }} </TitleHelper>
      <SensesSettings v-model="entry.senses" :entry="entry" />
    </ModalHelper>
  </div>
</template>

<script setup>
import { addonBlueprint } from "@/addonBlueprint";
</script>
<script>
import { defineAsyncComponent, hydrateOnIdle } from "vue";
import { Routining } from "../mixins/Routining";
import { Scheduling } from "../mixins/Scheduling";
import { Statusing } from "../mixins/Statusing";
import { Touring } from "../mixins/Touring";
import { Link2Icon, PlusIcon } from "lucide-vue-next";

export default {
  components: {
    OutputSettings: defineAsyncComponent({
      loader: () => import("@/components/output/OutputSettings.vue"),
      hydrate: hydrateOnIdle(),
    }),
    OutputDisplay: defineAsyncComponent({
      loader: () => import("@/components/output/OutputDisplay.vue"),
      hydrate: () => hydrateOnIdle(),
    }),

    LinksPopover: defineAsyncComponent(() =>
      import("@/components/links/partials/LinksPopover.vue"),
    ),
    PlusIcon,
    Link2Icon,
  },
  // provide() {
  //   return {
  //     display: {
  //       links: true,
  //       output: false,
  //     },
  //   };
  // },
  emits: ["showSettings"],
  mixins: [Statusing, Scheduling, Routining, Touring],
  props: {
    modelValue: Object,
    position: String,
  },
  data() {
    return {
      // linkedEntries: [],
      showSettings:
        !this.modelValue.input &&
        !this.modelValue.output &&
        this.modelValue.links.length <= 1 &&
        !this.modelValue.status_id &&
        !this.modelValue.schedule &&
        !this.modelValue.priority &&
        this.modelValue.time_trackings.length === 0,
      showTemplates: false,

      showSenseModal: this.$route.query.showModal === "true",
      dateCursor: this.$moment().format("YYYY-MM-DD"),
      isLazyLoaded: false,
      performanceRenderIndex: -1,
    };
  },
  mounted() {
    this.checkGetParameters();

    this.initResizeObserver();
    // setInterval(() => {
    //   this.$store.dispatch("pull", {
    //     filters: [{ key: "id", op: "eq", val: this.entry.id }],
    //     source: {
    //       file: "SETTINGSS",
    //       entry: this.entry.name,
    //     },
    //   });
    // }, 1000);
    // this.linkedEntries = this.entry.links
    //   .map((id) => this.$store.getters.entries.find((e) => e.id == id))
    //   .filter((e) => e);
    // this.isLazyLoaded = true;
    this.performanceRenderIndex = 0;
    this.performantRender();
  },
  beforeUnmount() {
    this.cleanupResizeObserver();
  },
  watch: {
    linkedEntries: {
      deep: true,
      handler(n, o) {
        if (n.length != o.length) {
          this.performantRender();
        }
      },
    },
    entry: {
      deep: true,
      handler(n, o) {
        if (n?.id != o?.id || n.links.length != o.links.length) {
          this.performanceRenderIndex = 0;
          this.performantRender();
        }
        if (!this.showSettings || n.id != o.id) {
          this.showSettings =
            !this.modelValue.input &&
            !this.modelValue.output &&
            this.modelValue.links.length <= 1 &&
            !this.modelValue.status_id;
          !this.modelValue.schedule &&
            !this.modelValue.priority &&
            this.modelValue.time_trackings.length === 0;
        }
        this.showTemplates = false;
        this.checkGetParameters();
      },
    },
  },
  computed: {
    entry: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    entries() {
      return this.$store.getters.entries?.filter((e) => e.deleted_at === null);
    },
    linkedEntries() {
      if (this.position == "modal") {
        return this.entry.links
          .map((id) => this.entries.find((e) => e.id == id))
          .filter((e) => e && e.id != this.$route.params.id);
      } else {
        return this.entry.links
          .map((id) => this.entries.find((e) => e.id == id))
          .filter((e) => e);
      }
    },
    statuses() {
      return this.getAvailableStatuses(this.entry);
    },
    status: {
      get() {
        return this.getStatusById(this.entry.status_id);
      },
      set(status) {
        this.setStatus(this.entry, status);
      },
    },
    links: {
      get() {
        return this.entry.links;
      },
      set(links) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.links = links;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
    hasPanelContent() {
      return (
        // this.showTemplates ||
        this.entry.output || this.entry.input || this.linkedEntries.length > 0
      );
    },
    output: {
      get() {
        return this.entry.output;
      },
      set(output) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.output = output;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
    input: {
      get() {
        return this.entry.input;
      },
      set(input) {
        // eslint-disable-next-line vue/no-mutating-props
        this.entry.input = input;
        this.$store.dispatch("push", {
          event: "entry_update",
          params: { entry: this.entry },
          entry: this.entry,
        });
      },
    },
  },
  methods: {
    performantRender() {
      // if (this) return;
      if (this.linkedEntries.length > this.performanceRenderIndex) {
        // this.performanceRenderIndex = this.linkedEntries.length;
        this.$nextTick(() => {
          if (this.linkedEntries.length > this.performanceRenderIndex) {
            this.performanceRenderIndex++;
            this.performantRender();
          }
        });
      } else {
        this.performanceRenderIndex = this.linkedEntries.length;
      }
    },
    checkGetParameters() {
      // console.log(this.$route.query);
      if (this.$route.query.sensesSettings == 1) {
        this.showSenseModal = true;
        this.$router.replace({
          query: { ...this.$route.query, sensesSettings: undefined },
        });
      }
    },

    initResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          for (let entry of entries) {
            const newHeight = entry.contentRect.height;
            if (this.$refs.outer_tinted_wrapper) {
              this.$refs.outer_tinted_wrapper.style.height = `${newHeight}px`;
            }
          }
        });
      });

      if (this.$refs.inner_tinted_wrapper) {
        this.resizeObserver.observe(this.$refs.inner_tinted_wrapper);
      }

      this.resizeObserver2 = new ResizeObserver((entries) => {
        window.requestAnimationFrame(() => {
          for (let entry of entries) {
            const newHeight = entry.contentRect.height;
            if (this.$refs.outer) {
              this.$refs.outer.$el.style.height = `${newHeight}px`;
            }
          }
        });
      });

      if (this.$refs.inner) {
        this.resizeObserver2.observe(this.$refs.inner);
      }
    },
    cleanupResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
      if (this.resizeObserver2) {
        this.resizeObserver2.disconnect();
      }
    },
  },
};
</script>

<style lang="scss" module>
:root {
  --ps-entry-settings-inner-background-color: var(--ps-color-neutral-50);
  &:where(:global(.dark), :global(.dark *)) {
    --ps-entry-settings-inner-background-color: var(--ps-color-neutral-900);
  }
}

.entrySettingsInner {
  @apply relative w-full overflow-y-visible rounded-3xl  transition-all;
  background-color: var(--ps-entry-settings-inner-background-color);
  // border: 1px solid var(--ps-base-border-color);
  box-sizing: content-box;
}

.entryDisplay {
  --ps-entry-display-padding-x: 1.25rem;
  --ps-entry-display-padding-y: 0;

  --ps-name-display-font-size: var(--ps-font-size-4xl);
  --ps-name-display-line-height: var(--ps-line-height-4xl);
  --ps-name-display-font-weight: bold;

  min-height: 48px; // ? This is to fix jumping(?) on isLazyMounted on the EntrySettings EntryDisplay

  &_smaller {
    --ps-name-display-font-size: var(--ps-font-size-2xl);
    --ps-name-display-line-height: var(--ps-line-height-2xl);
  }

  @apply w-full rounded-lg;
  &_wrapper {
    // @apply py-5;
    padding-top: 1.25rem;
    padding-bottom: 0.5rem;
  }
}

.buttonSettings {
  --ps-button-padding-x: 0.375rem;
  --ps-button-padding-y: 0.375rem;
  border-radius: 100%;
}

.entrySettingsPanel {
  // margin-bottom: 20px;
  // box-sizing: content-box; // @note for the gallery to not overflow?
  --ps-panel-padding: 0;
  transition:
    height 0.4s cubic-bezier(0.16, 1, 0.3, 1),
    width 800ms cubic-bezier(0.16, 1, 0.3, 1),
    border-radius 200ms cubic-bezier(0.16, 1, 0.3, 1);

  // overflow: hidden;
}

.addInputOutputButton {
  composes: button from "@/styles/buttons.module.scss";
  composes: buttonSecondary from "@/styles/buttons.module.scss";

  padding: 5px !important;
  margin: 5px !important;
  --ps-button-border-radius: 100%;
}

.linkedEntryWrapper {
  composes: entryWrapper from "@/styles/entries.module.scss";
  flex: 1;
}

.linkedEntryDisplay {
  --ps-name-display-font-size: var(--ps-font-size-lg);
  --ps-name-display-line-height: var(--ps-line-height-lg);

  composes: entryDisplay from "@/styles/entries.module.scss";

  position: sticky !important;
  top: 0;
}

.addLinksButton {
  composes: button from "@/styles/buttons.module.scss";
  composes: buttonPrimary from "@/styles/buttons.module.scss";
  padding: 0.375rem !important;
  --ps-button-border-radius: 100%;
}

.linkedEntryContextmenu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  // background-color: var(--ps-base-tinted-color);
  border-radius: 4px;
  padding: 8px;

  // --ps-button-color: var(--ps-base-background-color);
  // --ps-button-color-hover: var(--ps-base-background-color);

  @apply shadow-lg;
  > div {
    // @note to place the buttons in the center thirds
    flex: 1 1 0%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      flex-basis: 100%; // @note to make the title full width
      > span {
        // color: var(--ps-base-background-color);
        font-size: var(--ps-font-size-xs);
        text-transform: uppercase;
      }
    }
  }
}
</style>
